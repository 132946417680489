import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

import "../scss/about.scss"
import selfie from "../images/selfie_charles-2.jpg"

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <div className="cv">
        <div className="cv_section" id="cv_about_me">
          <h1 className="cv_title page_title" id="about_me_title">
            About Me
          </h1>
          <img src={selfie} alt="selfie charles" id="selfie" />
          <div className="cv_info">
            <p>
              I am a Digital Designer, Art Director and Developer with over 8
              years of experience.
              <br />I help my clients shape their digital content and produce
              their interactive communication.
            </p>
            {/*<p>*/}
            {/*  I am also a teaching assistant at the{" "}*/}
            {/*  <a*/}
            {/*    href="https://www.hesge.ch/head/en"*/}
            {/*    target="_blank"*/}
            {/*    rel="noopener noreferrer"*/}
            {/*  >*/}
            {/*    Geneva School of Art and Design (HEAD – Genève)*/}
            {/*  </a>{" "}*/}
            {/*  where I teach Unity development and game prototyping to second*/}
            {/*  year bachelor students, and the basics of art direction and*/}
            {/*  digital illustration to first year students.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*  I can demonstrate a large spectrum of art, design and technical*/}
            {/*  skills. I am a perpetual student of everything creative, always*/}
            {/*  challenging what I know and pushing back the boundaries of my*/}
            {/*  comfort zone.*/}
            {/*</p>*/}
            <p>
              My specialty lies at the crossroads between design and
              programming. <br />I am passionate about using code as a design
              tool to automate and augment the design process.
            </p>
            <p>
              I regularly work in partnership with{" "}
              <a
                href="https://tourmaline-studio.com/en"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tourmaline
              </a>
              , a Geneva-based digital studio.
            </p>
            <p>
              I am also an avid digital painter, you can see some of my work
              on&nbsp;
              <a
                href="https://www.instagram.com/chalas.ch/"
                target="_blank"
                rel="noopener noreferrer"
              >
                instagram
              </a>
              .
            </p>
            <p>
              I run two tumblr blogs:{" "}
              <a
                href="https://ultrazapping.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ultrazapping.net
              </a>
              , dedicated to modern and experimental graphic design
              <br />
              and{" "}
              <a
                href="https://objectsandmatters.tumblr.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                objects & matters
              </a>
              , where I collect photos that I use as references in my paintings.
            </p>
            {/*<p>*/}
            {/*  I also play around producing and mixing music (you can listen to*/}
            {/*  it on my{" "}*/}
            {/*  <a*/}
            {/*    href="https://soundcloud.com/cchalas"*/}
            {/*    target="_blank"*/}
            {/*    rel="noopener noreferrer"*/}
            {/*  >*/}
            {/*    soundcloud*/}
            {/*  </a>*/}
            {/*  ).*/}
            {/*</p>*/}
          </div>
        </div>

        {/*<div className="cv_section">*/}
        {/*  <h1 className="cv_title">Tools</h1>*/}
        {/*  <div className="cv_info">*/}
        {/*    Illustrator, Photoshop, InDesign, Sketch, Figma, Principle, Invision*/}
        {/*    <br />*/}
        {/*    After Effects, Premiere, Unity 3D, Cinema 4D, Zbrush*/}
        {/*    <br />*/}
        {/*    Ableton Live*/}
        {/*    <br />*/}
        {/*    MacOS , Windows, Linux*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div className="cv_section">*/}
        {/*  <h1 className="cv_title">Programming</h1>*/}
        {/*  <div className="cv_info">*/}
        {/*    Javascript, HTML5, CSS3,{" "}*/}
        {/*    <a*/}
        {/*      href="https://sass-lang.com/"*/}
        {/*      target="_blank"*/}
        {/*      rel="noopener noreferrer"*/}
        {/*    >*/}
        {/*      Sass*/}
        {/*    </a>*/}
        {/*    <br />*/}
        {/*    <a*/}
        {/*      href="https://reactjs.org/"*/}
        {/*      target="_blank"*/}
        {/*      rel="noopener noreferrer"*/}
        {/*    >*/}
        {/*      ReactJS*/}
        {/*    </a>*/}
        {/*    ,*/}
        {/*    <a*/}
        {/*      href="https://nextjs.org/"*/}
        {/*      target="_blank"*/}
        {/*      rel="noopener noreferrer"*/}
        {/*    >*/}
        {/*      &nbsp;Next.js*/}
        {/*    </a>*/}
        {/*    ,*/}
        {/*    <a*/}
        {/*      href="https://www.gatsbyjs.org/"*/}
        {/*      target="_blank"*/}
        {/*      rel="noopener noreferrer"*/}
        {/*    >*/}
        {/*      &nbsp;Gatsby*/}
        {/*    </a>*/}
        {/*    <br />*/}
        {/*    Git*/}
        {/*    <br />*/}
        {/*    C#, PHP, Python*/}
        {/*    <br />*/}
        {/*    Processing, Max Msp, Pure Data*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div className="cv_section">*/}
        {/*  <h1 className="cv_title">Nationalities</h1>*/}
        {/*  <div className="cv_info">Swiss & French</div>*/}
        {/*</div>*/}

        {/*<div className="cv_section">*/}
        {/*  <h1 className="cv_title">Languages</h1>*/}
        {/*  <div className="cv_info">*/}
        {/*    <span className="cv_important">French</span> - Native Language*/}
        {/*    <br />*/}
        {/*    <span className="cv_important">English</span> - Fluently spoken and*/}
        {/*    written*/}
        {/*    <br />*/}
        {/*    <span className="cv_important">German</span> - Basic understanding*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div id="cv_exp_edu">*/}
        {/*  <div className="cv_section" id="cv_experience">*/}
        {/*    <h1 className="cv_title">Experience</h1>*/}
        {/*    <div className="cv_info">*/}
        {/*      <div className="cv_date">2019 / Ongoing</div>*/}
        {/*      <div className="cv_position">*/}
        {/*        Independent Digital Designer, Art director and Developer*/}
        {/*      </div>*/}
        {/*      <div className="cv_organization">*/}
        {/*        <a*/}
        {/*          href="http://chalas.ch"*/}
        {/*          target="_blank"*/}
        {/*          rel="noopener noreferrer"*/}
        {/*        >*/}
        {/*          chalas.ch*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*      <div className="cv_place">Geneva</div>*/}

        {/*      <div className="cv_date">2015 / 2020</div>*/}
        {/*      <div className="cv_position">*/}
        {/*        Digital Designer, Art director and Developer*/}
        {/*      </div>*/}
        {/*      <div className="cv_organization">*/}
        {/*        <a*/}
        {/*          href="http://tourmaline-studio.com/en"*/}
        {/*          target="_blank"*/}
        {/*          rel="noopener noreferrer"*/}
        {/*        >*/}
        {/*          Tourmaline Studio*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*      <div className="cv_place">Geneva</div>*/}
        {/*      /!*<div className="cv_description">*!/*/}
        {/*      /!*  In this digital studio specialized in game design I am in charge*!/*/}
        {/*      /!*  of UX/UI design and web development. I also play a role in the*!/*/}
        {/*      /!*  art direction process.*!/*/}
        {/*      /!*</div>*!/*/}
        {/*      <div className="cv_date">2015 / 2020</div>*/}
        {/*      <div className="cv_position">*/}
        {/*        Teaching Assistant in Graphic Design and Visual Communication*/}
        {/*      </div>*/}
        {/*      <div className="cv_organization">*/}
        {/*        <a*/}
        {/*          href="https://www.hesge.ch/head/en"*/}
        {/*          target="_blank"*/}
        {/*          rel="noopener noreferrer"*/}
        {/*        >*/}
        {/*          Geneva School of Art and Design*/}
        {/*          <br />*/}
        {/*          (HEAD – Genève)*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*      <div className="cv_place">Geneva</div>*/}
        {/*      /!*<div className="cv_description">*!/*/}
        {/*      /!*  In the visual communication department of this internationally*!/*/}
        {/*      /!*  renowned school, I am teaching art direction and digital*!/*/}
        {/*      /!*  illustration basics to first year bachelor students, and*!/*/}
        {/*      /!*  programming and game prototyping to second year students.*!/*/}
        {/*      /!*  <br />I also manage and work on a lot of projects for clients of*!/*/}
        {/*      /!*  the school.*!/*/}
        {/*      /!*</div>*!/*/}
        {/*      <div className="cv_date">2014 / 2015</div>*/}
        {/*      <div className="cv_position">*/}
        {/*        Research assistant to Marguerite Humeau*/}
        {/*      </div>*/}
        {/*      <div className="cv_organization">*/}
        {/*        <a*/}
        {/*          href="https://www.hesge.ch/head/en"*/}
        {/*          target="_blank"*/}
        {/*          rel="noopener noreferrer"*/}
        {/*        >*/}
        {/*          Geneva School of Art and Design*/}
        {/*          <br />*/}
        {/*          (HEAD – Genève)*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*      <div className="cv_place">Geneva</div>*/}
        {/*      <div className="cv_date">2014 / 2015</div>*/}
        {/*      <div className="cv_position">*/}
        {/*        Research assistant to Nicolas Nova*/}
        {/*      </div>*/}
        {/*      <div className="cv_organization">*/}
        {/*        <a*/}
        {/*          href="https://www.hesge.ch/head/en"*/}
        {/*          target="_blank"*/}
        {/*          rel="noopener noreferrer"*/}
        {/*        >*/}
        {/*          Geneva School of Art and Design*/}
        {/*          <br />*/}
        {/*          (HEAD – Genève)*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*      <div className="cv_place">Geneva</div>*/}
        {/*      <div className="cv_date">2011 / 2012</div>*/}
        {/*      <div className="cv_position">Art director in apprenticeship</div>*/}
        {/*      <div className="cv_organization">Kids up Hill</div>*/}
        {/*      <div className="cv_place">Paris</div>*/}
        {/*      <div className="cv_date">2010 / 2011</div>*/}
        {/*      <div className="cv_position">*/}
        {/*        Graphic designer in apprenticeship*/}
        {/*      </div>*/}

        {/*      <div className="cv_organization">*/}
        {/*        <a*/}
        {/*          href="https://fullsix.fr"*/}
        {/*          target="_blank"*/}
        {/*          rel="noopener noreferrer"*/}
        {/*        >*/}
        {/*          Fullsix*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*      <div className="cv_place">Levallois-Perret</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  <div className="cv_section" id="cv_education">*/}
        {/*    <h1 className="cv_title">Education</h1>*/}
        {/*    <div className="cv_info">*/}
        {/*      <div className="cv_date">2014 / 2015</div>*/}
        {/*      <div className="cv_position">*/}
        {/*        Student-Researcher EnsadLab Sociable Media*/}
        {/*      </div>*/}
        {/*      <div className="cv_organization">*/}
        {/*        <a*/}
        {/*          href="https://www.ensad.fr/recherche/ensadlab"*/}
        {/*          target="_blank"*/}
        {/*          rel="noopener noreferrer"*/}
        {/*        >*/}
        {/*          École nationale supérieure des arts décoratifs*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*      <div className="cv_place"> Paris</div>*/}

        {/*      <div className="cv_date">2012 / 2014</div>*/}
        {/*      <div className="cv_position">Master Media Design</div>*/}
        {/*      <div className="cv_organization">*/}
        {/*        <a*/}
        {/*          href="https://www.hesge.ch/head/en"*/}
        {/*          target="_blank"*/}
        {/*          rel="noopener noreferrer"*/}
        {/*        >*/}
        {/*          Geneva School of Art and Design*/}
        {/*          <br />*/}
        {/*          (HEAD – Genève)*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*      <div className="cv_place"> Geneva</div>*/}

        {/*      <div className="cv_date">2010 / 2012</div>*/}
        {/*      <div className="cv_position">*/}
        {/*        Diploma of multimedia designer & producer*/}
        {/*      </div>*/}
        {/*      <div className="cv_organization">*/}
        {/*        <a*/}
        {/*          href="https://www.gobelins.fr"*/}
        {/*          target="_blank"*/}
        {/*          rel="noopener noreferrer"*/}
        {/*        >*/}
        {/*          Gobelins, l’école de l’image*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*      <div className="cv_place"> Paris</div>*/}

        {/*      <div className="cv_date">2008 / 2010</div>*/}
        {/*      <div className="cv_position">*/}
        {/*        BTS in Multimedia visual communication*/}
        {/*      </div>*/}
        {/*      <div className="cv_organization">Lycée Jacques Prévert</div>*/}
        {/*      <div className="cv_place"> Boulogne-Billancourt</div>*/}

        {/*      <div className="cv_date">2006 / 2007</div>*/}
        {/*      <div className="cv_position">Upgrade class for applied arts</div>*/}
        {/*      <div className="cv_organization">Lycée Jean Pierre Vernant</div>*/}
        {/*      <div className="cv_place"> Sèvres</div>*/}

        {/*      <div className="cv_date">2006</div>*/}
        {/*      <div className="cv_position">*/}
        {/*        Literary Baccalaureate, advanced English option*/}
        {/*      </div>*/}
        {/*      <div className="cv_organization">Lycée Louis Pasteur</div>*/}
        {/*      <div className="cv_place"> Besançon</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      {/*<Link to="/page-2/">Go to page 2</Link>*/}
    </Layout>
  )
}

export default AboutPage
